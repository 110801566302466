import {IDMSDataRendererSection} from "./IDMSDataRendererSection";
import IDataRendererMapping from "./IDataRendererMapping";
import IDataRendererMappingItem from "./IDataRendererMappingItem";
import {DCS, DCS_URL, DEFAULT_PREFIX, PREFIX, WEIGHT_CODE} from "../enums/BBCConfigMappingConstants";
import React from "react";
import {Cloud, Scale, Sell} from "@mui/icons-material";

class DMSDataRendererConverter {
    public static convertConfig = (mapping: IDataRendererMapping, configContents: string): IDMSDataRendererSection[] => {
        let dataSections: IDMSDataRendererSection[] = [];

        switch (mapping.converter) {
            case "config.properties":
                dataSections = this.convertConfigProperties(mapping, configContents);
                break;
            case "json":
                dataSections = this.convertConfigJson(mapping, configContents);
                break;
        }

        // Update the items' newValue property
        for (const section of dataSections) {
            for (const item of section.items) {
                item.newValue = item.originalValue;
            }
        }

        console.log(dataSections);

        return dataSections;
    };

    private static convertConfigProperties = (mapping: IDataRendererMapping, configContents: string) => {
        const dataSections: IDMSDataRendererSection[] = [];

        // process each mapping section, one by one

        for (const mappingSection of mapping.sections) {
            try {
                const dataSection: IDMSDataRendererSection = {
                    title: mappingSection.title,
                    maxColumns: mappingSection.metadata.maxColumns ?? 1,
                    items: []
                };

                for (const mappingItem of mappingSection.items) {
                    const lines = configContents.split("\n");

                    const idx = lines.findIndex(line => line.startsWith(mappingItem.field));

                    if (idx < 0) {
                        continue;
                    }

                    const configValues = lines[idx].split("=")[1];

                    dataSection.rawValue = configValues;

                    const separator = mappingItem.metadata.separator ?? ",";

                    const splitValueItems = configValues.split(separator).filter(i => {
                        return /\S/.test(i);
                    });

                    for (let item of splitValueItems) {
                        // parse config value, convert to IDMSDataRendererItem
                        item = item.trim();

                        const title = mappingItem.title ?? DMSDataRendererConverter.getTitle(mapping, mappingItem, item);

                        const processedValue = DMSDataRendererConverter.getValue(mapping, mappingItem, item);

                        if (!title || !processedValue) {
                            // skip items without a title, or a without a value
                            continue;
                        }

                        let options = [{value: DCS_URL[title[1]], title: title[1], icon: <Cloud/>}];

                        if (processedValue[1]) {
                            const weightIdx = lines.findIndex(line => line.includes("bagdrop.airline.weight.max." + processedValue[1]));

                            // If weight not found in config, use default weight with URL to applicationContext.xml
                            if (weightIdx > 0) {
                                options.push({
                                    value: null,
                                    title: lines[weightIdx].split("=")[1] + " KG",
                                    icon: <Scale/>
                                });
                            } else {
                                options.push({
                                    value: "https://bitbucket.org/bbcairport/bagomat/src/master/client/src/main/resources/applicationContext.xml",
                                    title: "Default",
                                    icon: <Scale/>
                                });
                            }

                            if (processedValue[2]) {
                                const prefixIdx = lines.findIndex(line => line.includes("abd.dcs.tag.prefix." + processedValue[2]));
                                if (prefixIdx > 0) {
                                    options.push({value: null, title: lines[prefixIdx].split("=")[1], icon: <Sell/>});
                                } else {
                                    options.push({
                                        value: null,
                                        title: DEFAULT_PREFIX[processedValue[2]],
                                        icon: <Sell/>
                                    });
                                }
                            }
                        }

                        dataSection.items.push({
                            id: item,
                            title: title[0],
                            airlineCode: processedValue[0],
                            originalValue: processedValue[0],
                            metadata: {
                                type: mappingItem.metadata.type,
                                options: options,
                                readonly: mappingItem.metadata.readonly,
                            }
                        });
                    }
                }

                if (dataSection.items.length > 0) {
                    dataSections.push(dataSection);
                }
            } catch (e) {
                console.warn(e, `failed to convert ${mapping.converter} config`);
            }
        }

        return dataSections;
    };

    private static convertConfigJson = (mapping: IDataRendererMapping, configContents: string) => {
        let dataSections: IDMSDataRendererSection[] = [];

        try {
            configContents = configContents.replace(/^\uFEFF/, '');

            const obj = JSON.parse(configContents);

            // process each mapping section, one by one

            for (const mappingSection of mapping.sections) {
                try {
                    const dataSection: IDMSDataRendererSection = {
                        maxColumns: mappingSection.metadata.maxColumns,
                        items: [],
                        title: mappingSection.title
                    };

                    for (const mappingItem of mappingSection.items) {
                        // get the field value
                        const pathToField = mappingItem.field.split(".");

                        let value = obj;

                        // if the object uses dot syntax, e.g. Person.Name.FirstName, then expand the object, by going down the tree

                        for (const p of pathToField) {
                            if (!value) {
                                continue;
                            }

                            value = value[p];
                        }

                        if (!value) {
                            continue;
                        }

                        // if it's not an array, convert it to a single-element array
                        if (!Array.isArray(value)) {
                            value = new Array(value);
                        }

                        for (const itemValue of (value as any[])) {
                            // convert each and every item to a data renderer item
                            const title = (typeof mappingItem.title === 'string') ? [mappingItem.title]
                                : mappingItem.title ?? DMSDataRendererConverter.getTitle(mapping, mappingItem, itemValue);

                            const processedValue = this.getValue(mapping, mappingItem, itemValue);

                            if (title === undefined || processedValue === undefined) {
                                // skip items without a title, or a without a value
                                continue;
                            }

                            dataSection.items.push({
                                title: title[0],
                                airlineCode: itemValue,
                                originalValue: processedValue[0],
                                id: mappingItem.field,
                                metadata: {
                                    type: mappingItem.metadata.type,
                                    options: [{
                                        value: DCS_URL[title[1]],
                                        title: title[1],
                                        icon: <Cloud/>
                                    }],
                                    readonly: mappingItem.metadata.readonly
                                }
                            });
                        }
                    }

                    if (dataSection.items.length > 0) {
                        dataSections.push(dataSection);
                    }

                } catch (e) {
                    console.warn(e, `failed to convert ${mapping.converter} config`);
                }
            }
        } catch (e: any) {
            dataSections = [{
                title: "Failed to parse config",
                maxColumns: 1,
                items: [{
                    id: "error",
                    title: "Error",
                    airlineCode: "error",
                    newValue: e.toString(),
                    originalValue: e.toString(),
                    metadata: {
                        type: "input"
                    }
                }]
            }];
        }

        return dataSections;
    };

    private static getTitle = (mapping: IDataRendererMapping, mappingItem: IDataRendererMappingItem, value: string): string [] | undefined => {
        switch (mapping.converter) {
            case "config.properties":
                return DMSDataRendererConverter.getTitleConfigProperties(mappingItem, value);
            case "json":
                return DMSDataRendererConverter.getTitleJsonObject(mappingItem, value);
            default:
                return [value];
        }
    };

    private static getValue = (mapping: IDataRendererMapping, mappingItem: IDataRendererMappingItem, value: string): string[] | undefined => {
        switch (mapping.converter) {
            case "config.properties":
                return DMSDataRendererConverter.getValueConfigProperties(mapping, mappingItem, value);
            case "json":
                return DMSDataRendererConverter.getValueJsonObject(mapping, mappingItem, value);
            default:
                return [value];
        }
    };

    private static getTitleConfigProperties = (mappingItem: IDataRendererMappingItem, value: string): string [] | undefined => {
        switch (mappingItem.metadata.type) {
            case "language":
                try {
                    const displayNames = new Intl.DisplayNames(['en'], {type: 'language'});
                    return [displayNames.of(value) ?? value];
                } catch (e) {
                    console.warn(e, "unknown language: " + value);
                    return [value];
                }
            case "airline_logo":
                switch (value) {
                    case "airlineCloudSas":
                    case "airlineSasPieceConcept":
                        return ["SAS", DCS.SK];
                    case "airlineBagsSas":
                        return ["SAS", DCS.BAGS];
                    case "airlineBagsWideroe":
                    case "airlineBags2Wideroe":
                        return ["Widerøe", DCS.BAGS];
                    case "airlineCloudKLM":
                    case "airlineKLM":
                        return ["KLM", DCS.AFKL];
                    case "airlineBagsKLM":
                        return ["KLM", DCS.BAGS];
                    case "airlineCloudWizzairWgh":
                        return ["Wizz Air", DCS.WGH];
                    case "airlineBagsWizzAir":
                        return ["Wizz Air", DCS.BAGS];
                    case "airlineBagsWizzAirUk":
                        return ["Wizz Air UK", DCS.BAGS];
                    case "airlineBagsLufttransport":
                        return ["Lufttransport", DCS.BAGS];
                    case "airlineBagsJettime":
                        return ["Jettime", DCS.BAGS];
                    case "airlineCloudThomasCookScandinavia":
                        return ["Sunclass", DCS.MT];
                    case "airlineBagsThomasCook":
                        return ["Thomas Cook", DCS.BAGS];
                    case "airlineCloudNorwegian":
                    case "airlineNorwegian":
                        return ["Norwegian", DCS.DY];
                    case "airlineBagsNorwegian":
                        return ["Norwegian", DCS.BAGS];
                    case "airlineCloudNorwegianIntrnational":
                        return ["Norwegian (International)", DCS.DY];
                    case "airlineCloudRyainairWgh":
                        return ["Ryanair", DCS.WGH];
                    case "airlineCloudRyainairUkWgh":
                        return ["Ryanair UK", DCS.WGH];
                    case "airlineCloudAirBalticWgh":
                        return ["AirBaltic", DCS.WGH];
                    case "airlineCloudWgh":
                        return ["WGH", DCS.WGH];
                    case "airlineBagsWgh":
                        return ["WGH", DCS.BAGS];
                    case "airlineCloudBraWgh":
                        return ["BIX", DCS.WGH];
                    case "airlineBagsBra":
                        return ["BIX", DCS.BAGS];
                    case "airlineCloudWizzair":
                        return ["Wizz Air", DCS.NAV];
                    case "airlineCloudWizzairUk":
                        return ["Wizz Air UK", DCS.NAV];
                    case "airlineCloudWizzairMalta":
                        return ["Wizz Air Malta", DCS.NAV];
                    case "airlineCloudAegean":
                    case "airlineAegean":
                        return ["Aegean", DCS.A3];
                    case "airlineCloudAegeanWgh":
                        return ["Aegean", DCS.WGH];
                    case "airlineBagsAegean":
                        return ["Aegean", DCS.BAGS];
                    case "airlineAirFrance":
                    case "airlineCloudAirFrance":
                        return ["Air France", DCS.AFKL];
                    case "airlineBagsAirFrance":
                        return ["Air France", DCS.BAGS];
                    case "airlineCloudLufthansa":
                        return ["Lufthansa", DCS.LH];
                    case "airlineBagsLufthansa":
                        return ["Lufthansa", DCS.BAGS];
                    case "airlineCloudAustrian":
                        return ["Austrian", DCS.LH];
                    case "airlineBagsAustrian":
                        return ["Austrian", DCS.BAGS];
                    case "airlineCloudSwiss":
                        return ["Swiss", DCS.LH];
                    case "airlineBagsSwiss":
                        return ["Swiss", DCS.BAGS];
                    case "airlineCloudBrussels":
                        return ["Brussels", DCS.LH];
                    case "airlineCloudEgyptair":
                        return ["Egyptair", DCS.MS];
                    case "airlineCloudEmirates":
                        return ["Emirates", DCS.EK];
                    case "airlineCloudFinnair":
                        return ["Finnair", DCS.AY];
                    case "airlineBagsFinnair":
                        return ["Finnair", DCS.BAGS];
                    case "airlineAtlantic":
                    case "operatorAtlanticNoTag":
                        return ["Atlantic", DCS.RC];
                    case "airlineBagsAtlanticAirways":
                        return ["Atlantic", DCS.BAGS];
                    case "airlineBagsTuiFreeBird":
                        return ["Freebird Airlines", DCS.BAGS];
                    case "airlineBagsTuiFlyNordic":
                        return ["TUI fly Nordic", DCS.BAGS];
                    case "airlineBagsTuiBHAir":
                        return ["TUI BH Air", DCS.BAGS];
                    case "airlineBagsEdelweiss":
                        return ["Edelweiss Airlines", DCS.BAGS];
                    case "airlineBagsIcelandair":
                        return ["Icelandair", DCS.BAGS];
                    case "airlineCloudIcelandair":
                        return ["Icelandair", DCS.FI];
                    case "airlineBagsIberia":
                        return ["Iberia", DCS.BAGS];
                    case "airlineBagsPrivateWings":
                        return ["Wings Private", DCS.BAGS];
                    case "airlineBagsTransaviaFrance":
                        return ["Transavia France", DCS.BAGS];
                    case "airlineBagsTransavia":
                        return ["Transavia", DCS.BAGS];
                    case "airlineBagsDat":
                        return ["DAT", DCS.BAGS];
                    case "airlineCloudOlympic":
                        return ["Olympic", DCS.A3];
                    case "airlineCloudKoreanair":
                        return ["Korean Air", DCS.KE];
                    case "airlineBagsCondor":
                        return ["Condor", DCS.BAGS];
                    case "airlineUnknown":
                    case "airlineBagsUnknownTagPrefix":
                    case "operator62nNoTag":
                        return undefined;
                    case "operatorFaeNoTag":
                        return undefined;
                    default:
                        alert("unknown airline: " + value);
                        return [value];
                }
            default:
                return [value];
        }
    };

    private static getTitleJsonObject = (mappingItem: IDataRendererMappingItem, value: string): string [] | undefined => {
        switch (mappingItem.metadata.type) {
            case "language":
                return DMSDataRendererConverter.getTitleConfigProperties(mappingItem, value);
            case "airline_logo":
                switch (value) {
                    case "DY":
                        return ["Norwegian", DCS.DY];
                    case "SK":
                        return ["SAS", DCS.SK];
                    case "D8":
                        return ["Norwegian Air International", DCS.DY];
                    case "KL":
                        return ["KLM", DCS.AFKL];
                    case "AF":
                        return ["Air France", DCS.AFKL];
                    case "WF":
                        return ["Widerøe", DCS.WIDEROE];
                    case "W4":
                        return ["Wizz Air Malta", DCS.NAV];
                    case "W4_WGH":
                        return ["Wizz Air Malta", DCS.WGH];
                    case "W4_INK":
                        return ["Wizz Air Malta", DCS.INK];
                    case "5W_INK":
                        return ["Wizz Air Abu Dhabi", DCS.INK];
                    case "W6":
                        return ["Wizz Air", DCS.NAV];
                    case "W6_WGH":
                        return ["Wizz Air", DCS.WGH];
                    case "W6_INK":
                        return ["Wizz Air", DCS.INK];
                    case "W9":
                        return ["Wizz Air UK", DCS.NAV];
                    case "DK":
                        return ["Sunclass Airlines", DCS.MT];
                    case "LH":
                        return ["Lufthansa", DCS.LH];
                    case "OS":
                        return ["Austrian", DCS.LH];
                    case "LX":
                        return ["Swiss", DCS.LH];
                    case "RC":
                        return ["Atlantic", DCS.RC];
                    case "A3":
                        return ["Aegean", DCS.A3];
                    case "A3_WGH":
                        return ["Aegean", DCS.WGH];
                    case "BLX":
                        return ["TUI fly Germany", DCS.SK];
                    case "6B":
                        return ["TUI fly Nordic", DCS.SK];
                    case "FI":
                        return ["Icelandair", DCS.FI];
                    case "AY":
                        return ["Finnair", DCS.FI];
                    case "SN":
                        return ["Brussels Airlines", DCS.LH];
                    case "WF_WGH":
                        return ["Widerøe", DCS.WGH];
                    case "WF_SAS":
                        return ["Widerøe", DCS.SK];
                    case "OA":
                        return ["Olympic", DCS.A3];
                    case "FR":
                        return ["Ryanair", DCS.INK];
                    case "FR_WGH":
                        return ["Ryanair", DCS.WGH];
                    case "RK":
                        return ["Ryanair UK", DCS.INK];
                    case "HV":
                        return ["Transavia", DCS.INK];
                    case "HV_EIN":
                        return ["Transavia Eindhoven", DCS.CLOUD];
                    case "TO":
                        return ["Transavia France", DCS.INK];
                    case "EZY":
                        return ["Easyjet", DCS.INK];
                    case "EJU":
                        return ["Easyjet Europe", DCS.INK];
                    case "EZS":
                        return ["Easyjet Switzerland", DCS.INK];
                    case "DX":
                        return ["Dat", DCS.SK];
                    case "KE":
                        return ["KoreanAir", DCS.KE];
                    case "TK":
                        return ["Turkish", DCS.TK];
                    case "TP":
                        return ["Tap", DCS.TP];
                    case "BIX":
                        return ["Bra", DCS.SK];
                    case "BIX_WGH":
                        return ["Bra", DCS.WGH];
                    case "MS":
                        return ["EgyptAir", DCS.MS];
                    case "BT":
                        return ["Air Baltic", DCS.BT];
                    case "OE":
                        return ["Laudamotion", DCS.INK];
                    case "SIXI":
                        return ["Alsie", DCS.SK];
                    case "XC":
                        return ["Corendon", DCS.SK];
                    case "XR":
                        return ["Corendon Europe", DCS.SK];
                    case "CD":
                        return ["Corendon Dutch", DCS.SK];
                    case "JTD":
                        return ["Jettime", DCS.SK];
                    case "OG_IPORT":
                        return ["Play", DCS.IPORT];
                    case "OG":
                        return ["Play", DCS.OG];
                    case "V7":
                        return ["Volotea", DCS.V7];
                }
                break;
            default:
                return [value];
        }
    };

    private static getValueConfigProperties = (mapping: IDataRendererMapping, mappingItem: IDataRendererMappingItem, value: string): string[] | undefined => {
        switch (mappingItem.metadata.type) {
            case "airline_logo":
                switch (value) {
                    case "airlineCloudSas":
                    case "airlineSasPieceConcept":
                    case "airlineBagsSas":
                        return ["SK", WEIGHT_CODE.sas, PREFIX.sk];
                    case "airlineBags2Wideroe":
                    case "airlineBagsWideroe":
                        return ["WF", WEIGHT_CODE.wf, PREFIX.wf];
                    case "airlineCloudKLM":
                    case "airlineBagsKLM":
                    case "airlineKLM":
                        return ["KL", WEIGHT_CODE.afkl, PREFIX.kl];
                    case "airlineCloudWizzairWgh":
                        return ["W6", WEIGHT_CODE.w6, PREFIX.wghW6];
                    case "airlineBagsWizzAir":
                        return ["W6", WEIGHT_CODE.w6, PREFIX.bagsW6];
                    case "airlineBagsWizzAirUk":
                        return ["W9", WEIGHT_CODE.w9, PREFIX.bagsW9];
                    case "airlineCloudThomasCookScandinavia":
                        return ["DK", WEIGHT_CODE.thomasCook, PREFIX.dk];
                    case "airlineBagsThomasCook":
                        return ["DK", WEIGHT_CODE.thomasCook, PREFIX.dk];
                    case "airlineCloudNorwegian":
                    case "airlineBagsNorwegian":
                    case "airlineNorwegian":
                        return ["DY", WEIGHT_CODE.norwegian, PREFIX.dy];
                    case "airlineCloudNorwegianIntrnational":
                        return ["D8", WEIGHT_CODE.norwegian, PREFIX.dy];
                    case "airlineCloudRyainairWgh":
                        return ["FR", WEIGHT_CODE.fr, PREFIX.wghFR];
                    case "airlineCloudRyainairUkWgh":
                        return ["FR", WEIGHT_CODE.rk, PREFIX.wghRK];
                    case "airlineCloudAirBalticWgh":
                        return ["BT", WEIGHT_CODE.bt, PREFIX.wghBT];
                    case "airlineCloudWgh":
                        return ["WGH", WEIGHT_CODE.wgh, PREFIX.wgh];
                    case "airlineCloudBraWgh":
                        return ["BIX", WEIGHT_CODE.bix, PREFIX.wghBIX];
                    case "airlineBagsBra":
                        return ["BIX", WEIGHT_CODE.bix, PREFIX.bagsBIX];
                    case "airlineBagsWgh":
                        return ["WGH", WEIGHT_CODE.wgh, PREFIX.wgh];
                    case "airlineCloudWizzairMalta":
                        return ["W4", WEIGHT_CODE.w4, PREFIX.w4];
                    case "airlineCloudWizzair":
                        return ["W6", WEIGHT_CODE.w6, PREFIX.w4];
                    case "airlineCloudWizzairUk":
                        return ["W9", WEIGHT_CODE.w9, PREFIX.w4];
                    case "airlineCloudAegean":
                    case "airlineBagsAegean":
                    case "airlineCloudAegeanWgh":
                    case "airlineAegean":
                        return ["A3", WEIGHT_CODE.a3, PREFIX.a3];
                    case "airlineCloudAirFrance":
                    case "airlineBagsAirFrance":
                    case "airlineAirFrance":
                        return ["AF", WEIGHT_CODE.afkl, PREFIX.af];
                    case "airlineCloudLufthansa":
                    case "airlineBagsLufthansa":
                        return ["LH", WEIGHT_CODE.lh, PREFIX.lh];
                    case "airlineCloudAustrian":
                    case "airlineBagsAustrian":
                        return ["OS", WEIGHT_CODE.os, PREFIX.os];
                    case "airlineCloudSwiss":
                    case "airlineBagsSwiss":
                        return ["LX", WEIGHT_CODE.lx, PREFIX.lx];
                    case "airlineCloudBrussels":
                        return ["SN", WEIGHT_CODE.lx, PREFIX.sn];
                    case "airlineCloudEgyptair":
                        return ["MS", WEIGHT_CODE.ms, PREFIX.ms];
                    case "airlineCloudEmirates":
                        return ["EK", WEIGHT_CODE.ek, PREFIX.ek];
                    case "airlineCloudFinnair":
                        return ["AY", WEIGHT_CODE.ay, PREFIX.ay];
                    case "airlineBagsFinnair":
                        return ["AY", WEIGHT_CODE.ay, PREFIX.bagsAY];
                    case "airlineAtlantic":
                    case "airlineBagsAtlanticAirways":
                        return ["RC", WEIGHT_CODE.atlantic, PREFIX.rc];
                    case "operatorAtlanticNoTag":
                        return ["RC", "-", PREFIX.rcNoTag];
                    case "airlineBagsTuiFreeBird":
                        return ["FH", WEIGHT_CODE.fh, PREFIX.bagsFH];
                    case "airlineBagsTuiFlyNordic":
                        return ["BLX", WEIGHT_CODE.blx, PREFIX.bagsBLX];
                    case "airlineBagsTuiBHAir":
                        return ["8H", WEIGHT_CODE.h8, PREFIX.bags8H];
                    case "airlineBagsIcelandair":
                    case "airlineCloudIcelandair":
                        return ["FI", WEIGHT_CODE.fi, PREFIX.fi];
                    case "airlineBagsIberia":
                        return ["IB", WEIGHT_CODE.ib, PREFIX.ib];
                    case "airlineBagsTransaviaFrance":
                        return ["TO", WEIGHT_CODE.to, PREFIX.to];
                    case "airlineBagsTransavia":
                        return ["HV", WEIGHT_CODE.hv, PREFIX.hv];
                    case "airlineBagsEdelweiss":
                        return ["WK", WEIGHT_CODE.wk, PREFIX.wk];
                    case "airlineBagsDat":
                        return ["DX", WEIGHT_CODE.dx, PREFIX.dx];
                    case "airlineCloudOlympic":
                        return ["OA", WEIGHT_CODE.a3, PREFIX.a3];
                    case "airlineCloudKoreanair":
                        return ["KE", WEIGHT_CODE.ke, PREFIX.ke];
                    case "airlineBagsLufttransport":
                        return ["D-", WEIGHT_CODE.d, PREFIX.d];
                    case "airlineBagsJettime":
                        return ["JTD", WEIGHT_CODE.jtd, PREFIX.jtd];
                    case "airlineBagsCondor":
                        return ["DE", WEIGHT_CODE.de, PREFIX.bagsDE];
                    case "airlineBagsPrivateWings":
                        return ["PWF", WEIGHT_CODE.pwf, PREFIX.bagsPWF];
                }
                break;
        }

        return [value];
    };

    private static getValueJsonObject = (mapping: IDataRendererMapping, mappingItem: IDataRendererMappingItem, value: string): string[] | undefined => {
        switch (mappingItem.metadata.type) {
            case "airline_logo":
                switch (value) {
                    case "W4_WGH":
                    case "W6_WGH":
                    case "5W_INK":
                    case "W4_INK":
                    case "W6_INK":
                        return ["W4"];
                    case "A3_WGH":
                        return ["A3"];
                    case "WF_WGH":
                    case "WF_SAS":
                        return ["WF"];
                    case "FR_WGH":
                        return ["FR"];
                    case "HV_EIN":
                        return ["HV"];
                    case "BIX_WGH":
                        return ["BIX"];
                    case "OG_IPORT":
                        return ["OG"];
                    case "SIXI":
                        return ["6I"];
                }
                break;
        }
        return [value];
    };
}

export default DMSDataRendererConverter;