import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Badge, Button,
    Card,
    CardContent, Checkbox,
    Chip,
    CircularProgress,
    CssBaseline, Fab,
    Fade, FormControl, FormControlLabel,
    Grid,
    Grow,
    IconButton, Input, InputLabel,
    LinearProgress, ListItemText, MenuItem, Select, Switch,
    Tooltip,
    Typography,
    Zoom
} from "@mui/material";
import {
    ABDState,
    DMSRESTApiClient, DMSWSClient,
    IBBCBridgeBagomatMessage,
    IBBCBridgeTagomatMessage,
    IDBNode,
    IDBUser, IDMSFile,
    IDMSNode, IDMSNodeBanState,
    IDMSServerStats, IDMSSoftwareBundle,
    IPortForwardingRule,
    TGMState,
    Util
} from "dms_commons";
import {Masonry} from "@mui/lab";
import {ArrowBack, Lock, Print, Settings, Warning} from "@mui/icons-material";
import DMSUserView from "./DMSUserView";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LocalStorageHelper from "../helpers/LocalStorageHelper";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {withStyles} from "@mui/styles";
import DMSNodesView from "./DMSNodesView";
import {WithSnackbarProps} from "notistack";
import Constants from "../Constants";
import ModalDialog from "./ModalDialog";

const combineLocations = [{
    src: "xflytoget-",
    dst: "flytoget"
}];

const DashPanelUsers = "users";
const DashPanelDcsServices = "dcs_services";
const DashPanelDmsForwarders = "dms_forwarders";

const dcsBaseUrl = "https://dcscloud.bbcairport.com/";

type DcsService = { url: string, isOk?: boolean, isLoading?: boolean, error?: Error }

type DMSForwarderHealth = { username: string, isOk?: boolean, isLoading?: boolean, error?: Error, displayName: string }

const dcsServices = [
    {url: "sbd/afkl"},
    {url: "tgm/afkl"},
    {url: "sbd/dy"},
    {url: "tgm/dy"},
    {url: "tgm/iport"},
    {url: "tgm/sas"},
    {url: "device-gateway"},
    {url: "sbd/icelandair"},
    {url: "tgm/icelandair"},
    {url: "tgm/ink"},
    {url: "sbd/thomascook"},
    {url: "tgm/thomascook"},
    {url: "sbd/lufthansa-group"},
    {url: "tgm/lufthansa-group"},
    {url: "sbd/wideroe"},
    {url: "tgm/wideroe"},
    {url: "sbd/aegean"},
    {url: "tgm/aegean"},
    {url: "sbd/lot"},
    {url: "tgm/lot"},
    {url: "sbd/wgh"},
    {url: "tgm/wgh"},
    {url: "sbd/atlantic"},
    {url: "tgm/atlantic"},
    {url: "tgm/play"},
    {url: "sbd/egyptair"},
    {url: "tgm/egyptair"},
    {url: "tgm/tui"},
    {url: "sbd/navitaire"},
    {url: "tgm/navitaire"},
    {url: "tgm/finnair"},
    {url: "sbd/finnair"},
    {url: "tgm/airbaltic"},
    {url: "tgm/easyjet"},
    {url: "sbd/emirates"},
    {url: "tgm/tap"},
    {url: "tgm/transavia"},
    {url: "tgm/turkish"},
    {url: "tgm/volotea"},
] as DcsService[];

const dmsForwarders = [{
    username: "dms_bix_forwarder@bbc.no",
    displayName: "BiX Forwarder"
}] as DMSForwarderHealth[];

dcsServices.forEach(s => {
    s.isLoading = true;
});

interface IProps extends WithSnackbarProps {
    classes: any;
    dmsNodes: Map<string, IDBNode>;
    dmsUsers: IDBUser[];
    dmsOnlineNodes: Map<string, IDMSNode>;
    portRules: IPortForwardingRule[];
    serverStats?: IDMSServerStats;
    isLoadingNodes: boolean;
    displaySnackbar: (message: string, variant) => void;
    logItems: string[];
    dmsClient: DMSWSClient;
    dmsRestClient: DMSRESTApiClient;
    onLoadDataRequested: () => void;
    logLine: (line: string) => void;
    dmsFiles: IDMSFile[];
    isLoadingFiles: boolean;
    bannedNodes: IDMSNodeBanState[];
    definedSoftware: IDMSSoftwareBundle[];
    onLoadPortRulesRequested: () => void;
    isLoadingPortRules: boolean;
}

interface IState {
    nodeLocations: Record<string, IDBNode[]>,
    uniqueLocations: Array<{ name: string, nodeCount: number }>,
    ready: boolean,
    dashPanelUsersExpanded: boolean | null,
    dashPanelDcsServicesExpanded?: boolean | null,
    dashPanelDmsForwardersExpanded?: boolean | null,
    selectedLocation?: string
    selectedNode?: IDBNode
    settingsVisible?: boolean;
    defaultSorting: "default" | "alpha";
    useFlatUI: boolean;
    enabledLocations: string[];
    filterLocations: boolean;
}

export default class DMSDashView extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const defaultSorting = LocalStorageHelper.getDashDefaultSorting() ?? "default";
        const useFlatUI = LocalStorageHelper.getDashUseFlatUI() ?? false;
        const enabledLocations = LocalStorageHelper.getDashEnabledLocations();
        const filterLocations = LocalStorageHelper.getDashFilterLocations();

        this.state = {
            nodeLocations: {},
            uniqueLocations: [],
            ready: false,
            dashPanelUsersExpanded: LocalStorageHelper.getDashPanelExpanded(DashPanelUsers),
            dashPanelDcsServicesExpanded: LocalStorageHelper.getDashPanelExpanded(DashPanelDcsServices),
            dashPanelDmsForwardersExpanded: LocalStorageHelper.getDashPanelExpanded(DashPanelDmsForwarders),
            defaultSorting: defaultSorting as any,
            useFlatUI: useFlatUI,
            enabledLocations: enabledLocations,
            filterLocations
        };

        this.performDcsHealthcheck();

        var style = document.createElement('style');
        style.textContent = 'body::-webkit-scrollbar { display: none; }';
        document.head.appendChild(style);
    }

    private getJwtToken = () => {
        return LocalStorageHelper.getAuthToken();
    };

    private performDcsHealthcheck = async () => {
        const healthcheckPromises = new Array<() => Promise<void>>();

        const jwtToken = this.getJwtToken();

        if (jwtToken === null) {
            console.log("missing jwt token");
            return;
        }

        for (const s of dcsServices) {
            healthcheckPromises.push(() => {
                return new Promise<void>(async resolve => {
                    s.isLoading = true;

                    let isOk = false;

                    try {

                        const url = dcsBaseUrl + s.url;

                        await Util.sleep(250);

                        const response = await this.props.dmsRestClient.postPerformHealthcheck(jwtToken, url);

                        isOk = response === 200;

                        if (!isOk) {
                            throw new Error("unexpected code: " + response);
                        }
                    } catch (e) {
                        s.error = e as any;
                    } finally {
                        s.isLoading = false;
                        s.isOk = isOk;

                        this.forceUpdate();
                        resolve();
                    }
                });
            });
        }

        await Util.promiseAllConcurrent(2, this.shuffle(healthcheckPromises));

        setTimeout(this.performDcsHealthcheck, 120 * 1000);
    };

    private shuffle = (array: Array<any>) => {
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex > 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    };

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (Object.keys(prevProps.dmsNodes).length !== Object.keys(this.props.dmsNodes).length
            || Object.keys(prevProps.dmsOnlineNodes).length !== Object.keys(this.props.dmsOnlineNodes).length
            || this.state.defaultSorting !== prevState.defaultSorting
            || this.state.enabledLocations !== prevState.enabledLocations) {

            //this.props.displaySnackbar("Node list updated", "info");

            let uniqueLocations = new Array<{ name: string, nodeCount: number }>();
            const nodeLocations: Record<string, IDBNode[]> = {};

            const {dmsOnlineNodes} = this.props;

            Object.keys(this.props.dmsNodes).forEach(key => {
                const n = this.props.dmsNodes[key] as IDBNode;

                let nodeLocation = n.location as string;

                for (const combineLocation of combineLocations) {
                    if (nodeLocation.indexOf(combineLocation.src) > -1) {
                        nodeLocation = combineLocation.dst;
                        break;
                    }
                }

                const targetIndex = uniqueLocations.findIndex(v => v.name === nodeLocation);

                if (targetIndex < 0) {
                    uniqueLocations.push({
                        name: nodeLocation,
                        nodeCount: 0
                    });
                } else {
                    uniqueLocations[targetIndex].nodeCount++;
                }

                if (!nodeLocations[nodeLocation]) {
                    nodeLocations[nodeLocation] = [];
                }

                nodeLocations[nodeLocation].push(n);
            });

            Object.keys(nodeLocations).forEach(nloc => {
                nodeLocations[nloc].sort((a: IDBNode, b: IDBNode) => {
                    const isNodeAOnline = dmsOnlineNodes && dmsOnlineNodes[a.uid] !== undefined;
                    const isNodeBOnline = dmsOnlineNodes && dmsOnlineNodes[b.uid] !== undefined;

                    return (isNodeAOnline === isNodeBOnline) ? 0 : isNodeAOnline ? -1 : 1;
                });
            });

            switch (this.state.defaultSorting) {
                default:
                case "default":
                    uniqueLocations.sort((a, b) => {
                        return b.nodeCount - a.nodeCount;
                    });
                    break;
                case "alpha":
                    uniqueLocations.sort((a, b) => {
                        const textA = a.name.toUpperCase();
                        const textB = b.name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                    break;
            }

            let selectedLocation: string | undefined;

            // we only have one location
            // make it active
            if (uniqueLocations?.length === 1) {
                selectedLocation = uniqueLocations[0].name;
            }

            this.setState({
                nodeLocations,
                uniqueLocations,
                selectedLocation,
                ready: true
            }, () => {
                if (Object.keys(this.props.dmsOnlineNodes).length < 1) {
                    window.location.reload();
                }
            });
        }
    }

    public render() {
        const {classes, isLoadingNodes} = this.props;

        if (isLoadingNodes) {
            return this.loadingView();
        }

        return <Grid container spacing={2} style={{padding: 8}}>
            <CssBaseline/>

            {
                this.state.selectedNode ? <DMSNodesView
                    tableKey={"nodes-dash"}
                    mode={"nodes"}
                    targetNodeInfoModeConfig={{
                        targetNode: this.state.selectedNode,
                        onCancel: () => this.setState({
                            selectedNode: undefined
                        })
                    }}
                    twoFactorHandler={request => {
                    }}
                    cancelTwoFactorRequest={() => {
                    }}
                    classes={classes}
                    dmsNodes={this.props.dmsNodes}
                    dmsFiles={this.props.dmsFiles}
                    isLoadingFiles={this.props.isLoadingFiles}
                    onLoadDataRequested={this.props.onLoadDataRequested}
                    renderFilesTable={() => null}
                    dmsOnlineNodes={this.props.dmsOnlineNodes}
                    bannedNodes={this.props.bannedNodes}
                    dmsClient={this.props.dmsClient}
                    dmsRestClient={this.props.dmsRestClient}
                    logLine={this.props.logLine}
                    definedSoftware={this.props.definedSoftware}
                    portRules={this.props.portRules}
                    onLoadPortRulesRequested={this.props.onLoadPortRulesRequested}
                    isLoadingPortRules={this.props.isLoadingPortRules}
                    enqueueSnackbar={this.props.enqueueSnackbar}
                    closeSnackbar={this.props.closeSnackbar}/> : undefined
            }

            <div style={{position: "fixed", bottom: 24, right: 8, opacity: 0.75, zIndex: -100}}>
                <img alt={"logo"} src={"bbclogo.png"}/>
            </div>


            <Grid item xs={12}>
                <Accordion
                    style={{opacity: this.state.dashPanelUsersExpanded ? 1 : 0.5}}
                    expanded={this.state.dashPanelUsersExpanded === null ? undefined : this.state.dashPanelUsersExpanded}
                    defaultExpanded
                    variant={this.state.useFlatUI ? "outlined" : "elevation"}
                    onChange={(event, expanded) => {
                        this.setState({dashPanelUsersExpanded: expanded});
                        LocalStorageHelper.setDashPanelExpanded(DashPanelUsers, expanded);
                    }}>
                    <StyledAccordionSummary expandIcon={<ArrowDownwardIcon/>}>
                        <Typography
                            textAlign="left"
                            variant="h5">{"Users"}</Typography>
                    </StyledAccordionSummary>
                    <AccordionDetails>
                        <Zoom in={this.state.dashPanelUsersExpanded === true} key={"users"} unmountOnExit>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    gap: 8,
                                    paddingTop: 8
                                }}>
                                {
                                    Object.values(this.props.dmsUsers).filter(u => {
                                        return this.props.dmsOnlineNodes[u.username] !== undefined;
                                    }).map(value => {
                                        return <DMSUserView
                                            chipSize={"small"}
                                            key={value.username}
                                            classes={classes}
                                            isUserOnline={username => true}
                                            username={value.username}/>;
                                    })
                                }
                            </div>
                        </Zoom>
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <Fab
                onClick={() => {
                    this.setState({
                        settingsVisible: true
                    });
                }} className={classes.fab} color="default" aria-label="settings">
                <Settings/>
            </Fab>

            <ModalDialog
                open={this.state.settingsVisible ?? false}
                title={"Settings"}
                hideOkButton={true}
                buttonCancelTitle={"Close"}
                onCancel={() => {
                    this.setState({settingsVisible: false});
                }}>
                <Grid>
                    <FormControlLabel control={<Switch checked={this.state.filterLocations} onChange={(ev) => {
                        const checked = ev.target?.checked ?? false;

                        this.setState({
                            filterLocations: checked
                        });

                        LocalStorageHelper.setDashFilterLocations(checked);

                    }}/>} label="Limit the locations"/>
                    <FormControl fullWidth>
                        <InputLabel>Selected Locations</InputLabel>
                        <Select
                            disabled={!this.state.filterLocations}
                            multiple
                            value={this.state.enabledLocations}
                            onChange={(ev) => {
                                const value = ev.target.value;

                                console.log(value);

                                this.setState({
                                    enabledLocations: typeof value === 'string' ? value.split(',') : value,
                                }, () => {
                                    LocalStorageHelper.setDashEnabledLocations(this.state.enabledLocations);
                                });
                            }}
                            input={<Input/>}
                            renderValue={(selected) => selected.filter(v => v.length > 0).join(', ')}
                        >
                            {this.state.uniqueLocations.map((location) => (
                                <MenuItem key={location.name} value={location.name}>
                                    <Checkbox
                                        checked={(this.state.enabledLocations?.indexOf(location.name) ?? -1) > -1}/>
                                    <ListItemText primary={location.name}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Sorting</InputLabel>
                        <Select
                            value={this.state.defaultSorting}
                            label="Sorting"
                            onChange={(ev) => {
                                LocalStorageHelper.setDashDefaultSorting(ev.target.value as any);

                                this.setState({
                                    defaultSorting: ev.target.value as any
                                });
                            }}
                        >
                            <MenuItem value={"default"}>By number of nodes</MenuItem>
                            <MenuItem value={"alpha"}>Alphabetically</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControlLabel control={<Switch checked={this.state.useFlatUI} onChange={(ev) => {
                        const checked = ev.target?.checked ?? false;

                        this.setState({
                            useFlatUI: checked
                        });

                        LocalStorageHelper.setDashUseFlatUI(checked);
                    }}/>} label="Use Flat UI"/>
                    <FormControl fullWidth>
                        <Button onClick={() => {
                            // @ts-ignore
                            window.location.reload(true);
                        }} color={"primary"} title={"Reload"}>Reload</Button>
                    </FormControl>
                    <FormControl fullWidth>
                        <Button onClick={() => {
                            this.setState({
                                enabledLocations: [],
                                useFlatUI: false,
                                defaultSorting: "default",
                                settingsVisible: false
                            }, () => {
                                LocalStorageHelper.setDashFilterLocations(false);
                                LocalStorageHelper.setDashEnabledLocations([]);
                                LocalStorageHelper.setDashUseFlatUI(false);
                                LocalStorageHelper.setDashDefaultSorting("default");
                            });
                        }} color={"error"} title={"Restore"}>Restore Defaults</Button>
                    </FormControl>
                </Grid>
            </ModalDialog>

            {
                this.renderDCSServiceStatus()
            }

            {
                this.renderForwarderStatus()
            }

            <Grid item xs={12} style={{marginBottom: 60}}>
                <Masonry columns={{xs: 3, sm: 4, md: 6, lg: 10, xl: 12}} spacing={2}>
                    {
                        this.state.selectedLocation ?
                            Object.values(this.state.nodeLocations[this.state.selectedLocation]).map((node, i) => {
                                return this.renderNodeCard(node, i);
                            }) : Object.values(this.state.uniqueLocations).map((location, i) => {
                                if (!this.state.filterLocations || (this.state.enabledLocations?.indexOf(location.name) ?? -1) > -1) {
                                    return this.renderLocationCard(location.name, i);
                                }
                            })
                    }
                </Masonry>
            </Grid>

            <div
                style={{
                    position: "fixed",
                    left: this.state.selectedLocation ? 16 : -100,
                    bottom: "50vh",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    opacity: this.state.selectedLocation ? 1 : 0,
                    transitionDelay: "1000ms",
                    animationDelay: "1000ms",
                    transition: "all 1000ms ease-in-out",
                    background: this.state.selectedLocation ? "rgba(0,255,0,0.1)" : undefined,
                    borderRadius: "50%"
                }}>
                <IconButton
                    size={"large"}
                    color={"primary"}
                    focusRipple={true}
                    disabled={this.state.selectedLocation === undefined || this.state.uniqueLocations.length < 2}
                    onClick={() => {
                        this.setState({
                            selectedLocation: undefined
                        });
                    }}>
                    <ArrowBack style={{height: 50, width: 50}}/>
                </IconButton>
            </div>

            {/*<Console
                style={{position: "absolute", left: 0, bottom: 0, right: 0, height: 120}}
                classes={classes}
                logItems={this.props.logItems}/>*/}

        </Grid>;
    }

    private renderDCSServiceStatus = () => {
        return <Grid item xs={12}>
            <Accordion
                variant={this.state.useFlatUI ? "outlined" : "elevation"}
                defaultExpanded
                style={{opacity: this.state.dashPanelDcsServicesExpanded ? 1 : 0.5}}
                expanded={this.state.dashPanelDcsServicesExpanded === null ? undefined : this.state.dashPanelDcsServicesExpanded}
                onChange={(event, expanded) => {
                    this.setState({dashPanelDcsServicesExpanded: expanded});
                    LocalStorageHelper.setDashPanelExpanded(DashPanelDcsServices, expanded);
                }}>
                <StyledAccordionSummary expandIcon={<ArrowDownwardIcon/>}>
                    <Typography
                        textAlign="left"
                        variant="h5">{"DCS Services"}</Typography>
                </StyledAccordionSummary>
                <AccordionDetails>
                    <Zoom in={this.state.dashPanelDcsServicesExpanded === true} key={"dcs_services"} unmountOnExit>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                gap: 8,
                                paddingTop: 8
                            }}>
                            {
                                dcsServices.map(s => {
                                    return <Tooltip title={s.error ? s.error.toString() : "OK"} key={s.url}>
                                        <Chip
                                            size={"small"}
                                            icon={s.isLoading ? <CircularProgress size={14}/> : s.isOk ?
                                                <CheckCircleIcon
                                                    sx={{"&&": {color: "rgb(81, 176, 51)"}}}/> :
                                                <Warning
                                                    sx={{"&&": {color: "rgba(239,146,2,0.85)"}}}/>}
                                            label={s.url}/>
                                    </Tooltip>;
                                })
                            }
                        </div>
                    </Zoom>
                </AccordionDetails>
            </Accordion>
        </Grid>;
    };

    private renderForwarderStatus = () => {
        const {dmsUsers, dmsOnlineNodes} = this.props;

        return <Grid item xs={12}>
            <Accordion
                variant={this.state.useFlatUI ? "outlined" : "elevation"}
                defaultExpanded
                style={{opacity: this.state.dashPanelDmsForwardersExpanded ? 1 : 0.5}}
                expanded={this.state.dashPanelDmsForwardersExpanded === null ? undefined : this.state.dashPanelDmsForwardersExpanded}
                onChange={(event, expanded) => {
                    this.setState({dashPanelDmsForwardersExpanded: expanded});
                    LocalStorageHelper.setDashPanelExpanded(DashPanelDmsForwarders, expanded);
                }}>
                <StyledAccordionSummary expandIcon={<ArrowDownwardIcon/>}>
                    <Typography
                        textAlign="left"
                        variant="h5">{"DMS Forwarders"}</Typography>
                </StyledAccordionSummary>
                <AccordionDetails>
                    <Zoom in={this.state.dashPanelDmsForwardersExpanded === true} key={"dms_forwarders"} unmountOnExit>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                gap: 8,
                                paddingTop: 8
                            }}>
                            {
                                dmsForwarders.map(s => {
                                    s.isOk = dmsOnlineNodes[s.username] !== undefined;

                                    return <Tooltip title={s.error ? s.error.toString() : "OK"} key={s.displayName}>
                                        <Chip
                                            size={"medium"}
                                            icon={s.isLoading ? <CircularProgress size={14}/> : s.isOk ?
                                                <CheckCircleIcon
                                                    sx={{"&&": {color: "rgb(81, 176, 51)"}}}/> :
                                                <Warning
                                                    sx={{"&&": {color: "rgba(239,146,2,0.85)"}}}/>}
                                            label={s.displayName}/>
                                    </Tooltip>;
                                })
                            }
                        </div>
                    </Zoom>
                </AccordionDetails>
            </Accordion>
        </Grid>;
    };

    private firestoreTimestampToDate = (timestamp: any) => {
        return new Date(timestamp._seconds * 1000 + +(timestamp._nanoseconds / 1000000000.0));
    };

    private isNodeStateFresh = (n: IDBNode) => {
        const nodeLastState = (n.lastBridgeMessage as IBBCBridgeTagomatMessage | IBBCBridgeBagomatMessage);

        // return true if newer than two hours
        return nodeLastState?.timestamp && (this.firestoreTimestampToDate(nodeLastState.timestamp).getTime() > (Date.now() - 1000 * 60 * 60 * 2));
    };

    private isNodeInError = (n: IDBNode) => {
        const nodeLastState = (n.lastBridgeMessage as IBBCBridgeTagomatMessage | IBBCBridgeBagomatMessage);

        if (nodeLastState?.text1 == "Paper low") {
            return false;
        }

        return nodeLastState?.state === ABDState.OUT_OF_ORDER || nodeLastState?.state === TGMState.SYSTEM_ERROR /*|| nodeLastState === TGMState.USER_ERROR*/;
    };

    private isNodePrinting = (n: IDBNode) => {
        const nodeLastState = (n.lastBridgeMessage as IBBCBridgeTagomatMessage);

        return this.isNodeStateFresh(n) && nodeLastState?.state === TGMState.PRINTING;
    };

    private isNodeInTransaction = (n: IDBNode) => {
        const nodeLastState = (n.lastBridgeMessage as IBBCBridgeTagomatMessage | IBBCBridgeBagomatMessage);

        if (
            this.isNodeStateFresh(n) &&
            nodeLastState?.state !== ABDState.CLOSED &&
            nodeLastState?.state !== ABDState.BAG_SENT &&
            nodeLastState?.state !== ABDState.OUT_OF_ORDER &&
            nodeLastState?.state !== ABDState.BAG_SEND_FAILURE &&
            nodeLastState?.state !== ABDState.BAG_READY_TO_BE_SCALED &&
            nodeLastState?.state !== ABDState.BAG_REJECTED_AFTER_SCALE &&
            nodeLastState?.state !== ABDState.CHOOSING_BAG_TYPE &&
            nodeLastState?.state !== ABDState.INITIALIZING &&
            nodeLastState?.state !== ABDState.REBOOT &&
            nodeLastState?.state !== ABDState.SHUTDOWN &&

            nodeLastState?.state !== TGMState.CLOSED &&
            nodeLastState?.state !== TGMState.INITIALIZING &&
            nodeLastState?.state !== TGMState.REBOOT &&
            nodeLastState?.state !== TGMState.USER_ERROR &&
            nodeLastState?.state !== TGMState.SYSTEM_ERROR &&
            nodeLastState?.state !== TGMState.TAGOMAT_WAITING_FOR_SCAN &&
            (nodeLastState?.state as string) !== "WAITING_FOR_SCAN"
        ) {
            return true;
        }

        return false;
    };

    private isNodeTransactionSuccess = (n: IDBNode) => {
        const nodeLastState = (n.lastBridgeMessage as IBBCBridgeTagomatMessage | IBBCBridgeBagomatMessage);

        return nodeLastState?.state === ABDState.BAG_SENT || nodeLastState?.state === TGMState.DONE_PRINTING;
    };

    private isNodeClosed = (n: IDBNode) => {
        const nodeLastState = (n.lastBridgeMessage as IBBCBridgeTagomatMessage | IBBCBridgeBagomatMessage);

        return nodeLastState?.state === ABDState.CLOSED || nodeLastState?.state === TGMState.CLOSED;
    };

    private renderLocationCard = (location: string, index: number) => {
        const {nodeLocations} = this.state;
        const nodes = nodeLocations[location];

        const onlineNodes = nodes.filter(n => this.props.dmsOnlineNodes[n.uid] !== undefined);
        const nodesOnlinePercentage = 100 / nodes.length * onlineNodes.length;

        const nodesOnlinePercentageThreshold = 40;

        const nodesInError = onlineNodes.filter(this.isNodeInError);

        const nodesPrinting = onlineNodes.filter(this.isNodePrinting);

        const nodesClosed = onlineNodes.filter(this.isNodeClosed);

        return <Zoom key={location} in={true} style={{transitionDelay: (index * 5) + "ms"}}>
            <Card
                variant={this.state.useFlatUI ? "outlined" : "elevation"}
                elevation={2}
                style={{cursor: "pointer"}}
                onClick={() => {
                    this.setState({
                        selectedLocation: location
                    });
                }}>
                <CardContent>
                    <Typography
                        whiteSpace={"nowrap"}
                        textOverflow={"ellipsis"}
                        textAlign="center"
                        sx={{
                            typography: {
                                xs: "caption",
                                sm: "body2",
                                md: "subtitle2",
                                xl: "subtitle1"
                            }
                        }}
                        style={{overflow: "hidden"}}>{location}</Typography>
                    <LinearProgress
                        color={nodesOnlinePercentage > nodesOnlinePercentageThreshold ? "primary" : "warning"}
                        style={{marginTop: 8, marginBottom: 8}}
                        variant={"determinate"} value={nodesOnlinePercentage}/>
                    <Typography textAlign={"center"} style={{overflow: "hidden", height: 25}} variant="body2"
                                color="text.secondary">{onlineNodes.length + "/" + nodes.length + " Online"}</Typography>


                    <div style={{
                        marginTop: 8,
                        height: 32,
                        display: "flex",
                        alignItems: "center",
                        columnGap: 8,
                        flexWrap: "wrap"
                    }}>
                        <Grow in={nodesInError?.length > 0} unmountOnExit={true}>
                            <Tooltip title={nodesInError.map(n => n.uid).join("\r\n")}>
                                <Badge badgeContent={nodesInError.length} color={"warning"}>
                                    <Warning/>
                                </Badge>
                            </Tooltip>
                        </Grow>

                        <Grow in={nodesPrinting?.length > 0} unmountOnExit={true}>
                            <Tooltip title={nodesPrinting.map(n => n.uid).join("\r\n")}>
                                <Badge badgeContent={nodesPrinting.length} color={"primary"}>
                                    <Print/>
                                </Badge>
                            </Tooltip>
                        </Grow>

                        <Grow in={nodesClosed?.length > 0} unmountOnExit={true}>
                            <Tooltip title={nodesClosed.map(n => n.uid).join("\r\n")}>
                                <Badge badgeContent={nodesClosed.length}>
                                    <Lock/>
                                </Badge>
                            </Tooltip>
                        </Grow>
                    </div>
                </CardContent>
            </Card>
        </Zoom>;
    };

    private renderNodeCard = (node: IDBNode, index: number) => {
        const {dmsOnlineNodes} = this.props;

        const isNodeOnline = dmsOnlineNodes[node.uid] !== undefined;

        const nodeLastState = (node.lastBridgeMessage as IBBCBridgeTagomatMessage | IBBCBridgeBagomatMessage);

        const isNodeInError = this.isNodeInError(node);

        const isNodePrinting = this.isNodePrinting(node);

        const isNodeInTransaction = this.isNodeInTransaction(node);

        const isNodeTransactionSuccess = this.isNodeTransactionSuccess(node);

        const isNodeClosed = this.isNodeClosed(node);

        const fallbackLogo = `airlines/BBC.png`;

        return <Zoom key={node.uid} in={true} style={{transitionDelay: (index * 5) + "ms"}}>
            <Card
                variant={this.state.useFlatUI ? "outlined" : "elevation"}
                elevation={2}
                style={{cursor: "pointer"}}
                onClick={(event) => {
                    this.setState({
                        selectedNode: node
                    });
                }}>
                <CardContent>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        columnGap: 2
                    }}>
                        <div style={{
                            height: 8,
                            width: 8,
                            marginLeft: -8,
                            marginTop: 4,
                            marginRight: 2,
                            borderRadius: "50%",
                            background: isNodeOnline ? "rgb(104,180,53)" : "rgb(238,40,40)",
                            transition: "all 250ms ease-in-out"
                        }}/>
                        <Typography
                            whiteSpace={"nowrap"}
                            textOverflow={"ellipsis"}
                            textAlign="center"
                            sx={{
                                typography: {
                                    xs: "caption",
                                    sm: "body2",
                                    md: "subtitle2",
                                    xl: "subtitle1"
                                }
                            }}
                            style={{overflow: "hidden"}}>{node.uid}</Typography>
                    </div>

                    <div style={{
                        marginTop: 8,
                        height: 32,
                        display: "flex",
                        alignItems: "center",
                        columnGap: 8,
                        flexWrap: "wrap"
                    }}>
                        <Grow in={isNodeOnline && isNodeInError} unmountOnExit={true}>
                            <Warning color={"warning"}/>
                        </Grow>

                        <Grow in={isNodeOnline && isNodePrinting} unmountOnExit={true}>
                            <Print color={"primary"}/>
                        </Grow>

                        <Grow in={isNodeOnline && isNodeInTransaction} unmountOnExit={true}>
                            {isNodeTransactionSuccess ? <CheckCircleIcon color={"primary"}/> :
                                <CircularProgress size={24}/>}
                        </Grow>

                        <Grow in={isNodeOnline && isNodeClosed} unmountOnExit={true}>
                            <Lock/>
                        </Grow>

                        <Grow in={(nodeLastState as any)?.model?.flight?.airline != undefined}
                              unmountOnExit={true}>
                            <img alt={"airline"} style={{height: 24, margin: 4}}
                                 onError={({currentTarget}) => {
                                     currentTarget.onerror = null; // prevents looping
                                     currentTarget.src = fallbackLogo;
                                 }}
                                 src={"/airlines/" + (nodeLastState as any)?.model?.flight?.airline + ".png"}/>
                        </Grow>
                    </div>
                </CardContent>
            </Card>
        </Zoom>;
    };

    private loadingView = () => {
        const {} = this.props;

        return <div style={{
            height: "100%",
            display: "flex",
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center"
        }}>
            <CssBaseline/>
            <Grow in={true} timeout={1000}>
                <Fade in={true}>
                    <div style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <img alt={"logo"} style={{margin: 32}} src={"bbclogo.png"}/>
                        <Typography
                            whiteSpace={"nowrap"}
                            textOverflow={"ellipsis"}
                            textAlign="center"
                            style={{overflow: "hidden", margin: 32}}
                            variant="h4">{"DMS Dash " + Constants.clientVersion.major + "." + Constants.clientVersion.minor + "." + Constants.clientVersion.build}</Typography>
                        <LinearProgress color={"primary"} variant={"indeterminate"}
                                        style={{width: "50%"}}/>
                    </div>
                </Fade>
            </Grow>
        </div>;
    };
}

const StyledAccordionSummary = withStyles({
    root: {
        minHeight: 15,
        maxHeight: 48,
        '&.Mui-expanded': {}
    },
    expandIcon: {
        order: -1
    }
})(AccordionSummary);