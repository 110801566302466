import React from "react";
import {
    CircularProgress,
    Container,
    Fab,
    FormControl,
    Grid,
    InputLabel,
    LinearProgress,
    MenuItem,
    Paper,
    Select,
    Switch,
    TextField,
    Chip,
    IconButton, Tooltip, Typography
} from "@mui/material";
import {VariantType, WithSnackbarProps} from "notistack";
import {DMSMessageFactory, DMSMethod, DMSWSClient, IDBNode, IDMSNode, IPortForwardingRule, Util} from "dms_commons";
import AddIcon from "@mui/icons-material/Add";
import LocalStorageHelper from "../helpers/LocalStorageHelper";
import MaterialTable, {Column} from "@material-table/core";
import INewPortRuleRequest from "../models/INewPortRuleRequest";
import SignalCellularAltTwoToneIcon from "@mui/icons-material/SignalCellularAltTwoTone";
import SignalCellularConnectedNoInternet0BarTwoToneIcon
    from "@mui/icons-material/SignalCellularConnectedNoInternet0BarTwoTone";
import ModalDialog from "./ModalDialog";
import fileDownload from "js-file-download";
import DMSUserView from "./DMSUserView";
import {Autocomplete} from "@mui/lab";
import {ArrowForward, Delete, Edit, Http, MoveDown, SettingsEthernet} from "@mui/icons-material";

interface IProps extends WithSnackbarProps {
    classes: any;
    onLoadDataRequested: () => void;
    isLoadingPortRules: boolean;
    portRules: IPortForwardingRule[],
    logLine: (line: string) => void;
    displaySnackbar: (message: string, variant: VariantType) => void;
    dmsClient: DMSWSClient;
    dmsNodes: Map<string, IDBNode>;
    targetNode?: IDBNode;
    dmsOnlineNodes: Map<string, IDMSNode>;
    lightMode?: boolean;
}

interface IState {
    newPortRuleRequest?: INewPortRuleRequest;
    isCreatingRuleInProgress: boolean;
    isDeletingRuleInProgress: boolean;
    deletionTargetPortRule?: IPortForwardingRule;
    dmsPortRulesTableColumns: Column<IPortForwardingRule>[];
    supportedPorts: Array<{ serviceName: string, portNumber: number, scheme: string, protocol: "http" | "tcp" }>;
}

export default class DMSPortForwardingRulesView extends React.Component<IProps, IState> {
    public state: IState = {
        isCreatingRuleInProgress: false,
        isDeletingRuleInProgress: false,
        dmsPortRulesTableColumns: [],
        supportedPorts: [
            {serviceName: "http", portNumber: 80, scheme: "http", protocol: "http"},
            {serviceName: "http", portNumber: 8080, scheme: "http", protocol: "http"},
            {serviceName: "https", portNumber: 443, scheme: "https", protocol: "http"},
            {serviceName: "VNC", portNumber: 5900, scheme: "vnc", protocol: "tcp"},
            {serviceName: "VNC", portNumber: 13088, scheme: "vnc", protocol: "tcp"},
            {serviceName: "RDP", portNumber: 3389, scheme: "rdp", protocol: "tcp"},
            {serviceName: "SMB", portNumber: 445, scheme: "smb", protocol: "tcp"},
            {serviceName: "SSH", portNumber: 22, scheme: "ssh", protocol: "tcp"},
            {serviceName: "Straton com", portNumber: 1200, scheme: "tcp", protocol: "tcp"},
            {serviceName: "QJ71tcp", portNumber: 5002, scheme: "tcp", protocol: "tcp"},
            {serviceName: "PLCtcp", portNumber: 5007, scheme: "tcp", protocol: "tcp"},
            {serviceName: "PLCFXtcp", portNumber: 5551, scheme: "tcp", protocol: "tcp"},
            {serviceName: "Zenon", portNumber: 1101, scheme: "tcp", protocol: "tcp"},
            {serviceName: "Tagomat V2", portNumber: 8085, scheme: "http", protocol: "http"}
        ]
    };

    public componentDidMount() {
        this.initDmsPortRulesColumns();
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.portRules === prevProps.portRules && this.props.dmsOnlineNodes === prevProps.dmsOnlineNodes) {
            return;
        }

        this.initDmsPortRulesColumns();
    }

    private initDmsPortRulesColumns = () => {
        const {dmsOnlineNodes, classes} = this.props;

        const dmsPortRulesTableColumns: Column<IPortForwardingRule>[] = [
            {
                title: "Target Node",
                field: "targetNode",
                align: "left",
                filtering: false,
                render: data => {
                    const isNodeOnline = data.targetNode && dmsOnlineNodes && dmsOnlineNodes[data.targetNode] !== undefined;

                    return (<Chip style={{
                        backgroundColor: isNodeOnline ? undefined : "rgba(119, 97, 64, 0.85)"
                    }} size={"small"} icon={isNodeOnline ?
                        <SignalCellularAltTwoToneIcon aria-label={"online"} htmlColor="rgb(0,225,0)"/> :
                        <SignalCellularConnectedNoInternet0BarTwoToneIcon aria-label={"offline"}
                                                                          htmlColor={"orange"}/>}
                                  label={`${data.targetNode}`}/>);
                }
            },
            {
                title: "Node Port",
                field: "nodePort",
                align: "left",
                filtering: false,
                render: rowData => {
                    const svc = this.state.supportedPorts.find(s => s.portNumber === rowData.nodePort);
                    return <p>{`${rowData.nodePort}${svc ? " (" + svc.serviceName + ")" : ""}`}</p>;
                }
            },
            {
                title: "Node Host",
                field: "hostname",
                align: "left",
                filtering: false,
                render: rowData => {
                    return <Tooltip
                        title={rowData.hostname === "localhost" ? "Direct tunnel" : `Node2Node tunnel to '${rowData.hostname}' via '${rowData.targetNode}'`}>
                        <Chip
                            color={rowData.hostname !== "localhost" ? "warning" : undefined}
                            size={"small"}
                            label={`${rowData.hostname ?? "localhost"}`}
                            icon={rowData.hostname !== "localhost" ? <MoveDown/> : <ArrowForward/>}/>
                    </Tooltip>;
                }
            },
            {
                title: "Protocol",
                field: "protocol",
                align: "left",
                filtering: false,
                render: rowData => {
                    return <Chip size={"small"} icon={rowData.protocol === "http" ? <Http/> : <SettingsEthernet/>}
                                 label={`${rowData.protocol ?? "tcp"}`}></Chip>;
                }
            },
            {
                title: "Public Port",
                field: "publicPort",
                align: "left",
                filtering: false,
            },
            {
                title: "Status",
                field: "sessionStats",
                align: "center",
                filtering: false,
                render: data => {
                    const isNodeOnline = data.targetNode && dmsOnlineNodes && dmsOnlineNodes[data.targetNode] !== undefined;

                    return <div
                        style={{display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center"}}>
                        <div style={{
                            background: isNodeOnline ? (data.isInUse ? "rgb(104,180,53)" : "rgb(196,105,43)") : "rgb(238,40,40)",
                            height: "8px",
                            width: "8px",
                            marginTop: "2px",
                            marginRight: "8px",
                            borderRadius: "4px",
                            transition: "all ease-in-out 250ms"
                        }}/>
                        <pre>{`In: ${Util.formatBytes(data.sessionStats?.bytesIn ?? 0)} Out: ${Util.formatBytes(data.sessionStats?.bytesOut ?? 0)}`}</pre>
                    </div>;
                }
            },
            {
                title: "Enabled",
                field: "isEnabled",
                align: "center",
                filtering: false,
                render: data => {
                    return <div
                        style={{justifyContent: "center", display: "flex", alignItems: "center"}}>
                        <Switch
                            onChange={(e) => {
                                e.preventDefault();
                                const checked = e.target?.checked ?? false;
                                this.setPortRuleEnabled(data, checked);
                            }
                            }
                            disabled={data["isSaving"]}
                            checked={data.isEnabled === true}
                            color="primary"
                        />
                        <CircularProgress style={{
                            opacity: data["isSaving"] ? 1 : 0,
                            transition: "all 150ms ease-in-out",
                            color: "lightgrey"
                        }} size={18}
                                          variant={"indeterminate"}/>
                    </div>;
                }
            },
            {
                title: "Enabled By",
                field: "enabledBy",
                align: "left",
                filtering: false,
                render: rowData => {
                    return <DMSUserView classes={classes} username={rowData.enabledBy ?? ""}
                                        isUserOnline={(username => {
                                            return rowData.enabledBy === "dms_server" || (rowData.enabledBy !== undefined && dmsOnlineNodes && dmsOnlineNodes[rowData.enabledBy] !== undefined);
                                        })}/>;
                }
            },
            {
                title: "Options",
                align: "right",
                sorting: false,
                render: rowData => {
                    const isNodeOnline = dmsOnlineNodes && dmsOnlineNodes[rowData.targetNode ?? ""] !== undefined;

                    return (<div style={{display: "flex", flexDirection: "row"}}>
                            <IconButton
                                disabled={!isNodeOnline}
                                size={"small"}
                                onClick={(event) => {
                                    this.connectToPortForwarding(rowData);
                                }}>
                                <SettingsEthernet/>
                            </IconButton>
                            <IconButton
                                size={"small"}
                                onClick={(event) => {
                                    this.setState({
                                        newPortRuleRequest: {
                                            hostname: rowData.hostname,
                                            protocol: rowData.protocol,
                                            nodePort: rowData.nodePort,
                                            publicPort: rowData.publicPort,
                                            // @ts-ignore
                                            targetNode: this.props.targetNode ?? {
                                                uid: rowData.targetNode
                                            }
                                        }
                                    });
                                }}>
                                <Edit/>
                            </IconButton>
                            <IconButton
                                size={"small"}
                                onClick={(event) => {
                                    if (rowData?.isEnabled) {
                                        this.props.displaySnackbar("Please disable the port forwarding rule before deleting it.", "warning");
                                    }

                                    this.setState({
                                        deletionTargetPortRule: rowData
                                    });
                                }}>
                                <Delete/>
                            </IconButton>
                        </div>
                    );
                }
            }
        ];

        dmsPortRulesTableColumns.forEach(c => {
            c.width = 1;
        });

        this.setState({dmsPortRulesTableColumns});
    };

    public render() {
        const {lightMode, classes, portRules, isLoadingPortRules, targetNode} = this.props;
        const {dmsPortRulesTableColumns} = this.state;

        return <Grid item xs={12}>
            <MaterialTable
                components={lightMode ? {Container: props => <Paper elevation={0} {...props} />} : undefined}
                isLoading={isLoadingPortRules}
                title="Port Forwarding Rules"
                columns={dmsPortRulesTableColumns}
                data={targetNode ? portRules.filter(pr => pr.targetNode === targetNode.uid) : portRules}
                options={{
                    idSynonym: "targetNode",
                    headerStyle: {
                        backgroundColor: "rgb(65,65,65)",
                    },
                    showTitle: !this.props.lightMode && true,
                    header: !this.props.lightMode,
                    draggable: false,
                    pageSize: 25,
                    pageSizeOptions: [25, 50, 100],
                    filtering: !this.props.lightMode && false,
                    loadingType: "overlay",
                    padding: this.props.lightMode ? "dense" : "default",
                    emptyRowsWhenPaging: false
                }}
                localization={{
                    body: {
                        emptyDataSourceMessage: <Typography>No port forwarding rules</Typography>
                    }
                }}
                actions={[
                    {
                        icon: 'add',
                        tooltip: 'Add',
                        isFreeAction: true,
                        onClick: () => {
                            const newPortRuleRequest: INewPortRuleRequest = {};

                            newPortRuleRequest.hostname = "localhost";

                            if (this.props.targetNode != undefined) {
                                newPortRuleRequest.targetNode = targetNode;
                            }

                            this.setState({
                                newPortRuleRequest
                            });
                        }
                    },
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: this.props.onLoadDataRequested
                    },
                ]}
                onRowClick={() => {

                }}
            />
            <Grid>
                {
                    this.props.lightMode ? undefined : <Fab
                        onClick={() => {
                            this.setState({
                                newPortRuleRequest: {
                                    hostname: "localhost"
                                }
                            });
                        }} className={classes.fab} color="primary" aria-label="add">
                        <AddIcon/>
                    </Fab>
                }
                {this.renderNewPortRuleDialog()}
                {this.renderDeletePortRuleDialog()}
            </Grid>
        </Grid>;
    }

    private connectToPortForwarding = (targetPortRule: IPortForwardingRule) => {
        if (!targetPortRule) {
            return;
        }

        const schemeInfo = this.state.supportedPorts.find(s => s.portNumber === targetPortRule.nodePort);

        if (schemeInfo) {
            const host = "tunnel.bbcairport.com";

            let handled = false;

            switch (schemeInfo.scheme) {
                case "rdp":
                    const rdpFile = Util.generateRdpFile(host + ":" + targetPortRule.publicPort);
                    this.props.displaySnackbar("Downloading RDP file, use it to connect to the node.", "info");
                    setTimeout(() => {
                        fileDownload(rdpFile, targetPortRule.targetNode + ".rdp", 'application/rdp');
                    }, 2000);
                    handled = true;
                    break;
                case "http":
                case "https":
                case "vnc":
                case "smb":
                case "ssh":
                    this.props.displaySnackbar(`Opening ${schemeInfo.serviceName} client, please make sure a handler for scheme '${schemeInfo.scheme}' is registered on your device.`, "info");
                    setTimeout(() => {
                        window.open(`${schemeInfo.scheme}://${host}:${targetPortRule.publicPort}`);
                    }, 2000);
                    handled = true;
                    break;
                default:
                    break;
            }

            if (!handled) {
                this.props.displaySnackbar("Unhandled protocol " + schemeInfo.scheme, "error");
            }
        } else {
            this.props.displaySnackbar("No scheme is known for port " + targetPortRule.nodePort, "error");
        }
    };

    private generateRandomNumber = (min: number, max: number, exclude: number[] | undefined = undefined): number => {
        const num = Math.floor(Math.random() * (max - min + 1)) + min;

        if (exclude) {
            if (exclude.indexOf(num) > -1) {
                return this.generateRandomNumber(min, max, exclude);
            }
        }

        return num;
    };

    private validateNewPortRuleRequest = Util.debounce((newPortRuleRequest: INewPortRuleRequest, generatePort: boolean) => {
        let isValid: boolean;

        if (!newPortRuleRequest) {
            isValid = false;
        } else {
            isValid = newPortRuleRequest.nodePort !== undefined
                && newPortRuleRequest.targetNode !== undefined
                && newPortRuleRequest.hostname !== undefined;
        }

        let publicPort: number | undefined = newPortRuleRequest.publicPort;

        if (!publicPort || generatePort) {
            if (newPortRuleRequest.nodePort && newPortRuleRequest.targetNode) {
                const portsToExclude = this.props.portRules.map(p => {
                    return p.publicPort;
                });

                publicPort = this.generateRandomNumber(1900, 6000, portsToExclude);
            }
        }

        if (isValid) {
            isValid = isValid && (typeof publicPort === 'number') && (publicPort >= 1900 && publicPort <= 6000);

            if (isValid && publicPort !== undefined) {
                const portsToExclude = this.props.portRules.map(p => p.publicPort);
                if (portsToExclude.includes(publicPort)) {
                    isValid = false;
                }
            }
        }

        this.setState(prevState => ({
            newPortRuleRequest: {
                ...prevState.newPortRuleRequest,
                publicPort,
                validates: isValid
            }
        }));
    }, 500);

    private forceUpdateDebounced = Util.debounce(() => {
        this.forceUpdate();
    }, 100);

    private setPortRuleEnabled = async (portRule: IPortForwardingRule, enabled: boolean) => {
        portRule["isSaving"] = true;
        this.forceUpdateDebounced();

        this.forceUpdate();
        try {
            const msg = DMSMessageFactory.newMessage(DMSMethod.SET_PORT_FORWARDING_RULE_ENABLED, {
                uid: portRule!.targetNode,
                enabled
            });

            await this.props.dmsClient.sendMessage(msg, true);

        } catch (error: any) {
            let errorText: string;

            if ("description" in error) {
                errorText = error.description;
            } else {
                errorText = JSON.stringify(error);
            }

            this.props.displaySnackbar!(errorText, "error");
            this.props.logLine(errorText);
        } finally {
            portRule["isSaving"] = false;
            this.forceUpdateDebounced();
        }
    };

    private renderNewPortRuleDialog = () => {
        const {newPortRuleRequest, isCreatingRuleInProgress} = this.state;

        return (<ModalDialog
            open={newPortRuleRequest !== undefined}
            buttonOkIsLoading={isCreatingRuleInProgress}
            buttonOkDisabled={!newPortRuleRequest?.validates || isCreatingRuleInProgress}
            buttonCancelDisabled={isCreatingRuleInProgress}
            title={`Create a new port forwarding rule`}
            buttonOkTitle={"Save"}
            message={""}
            onOk={() => {
                if (newPortRuleRequest) {
                    this.onCreatePortRuleRequested(newPortRuleRequest);
                }
            }} onCancel={() => {
            this.setState({newPortRuleRequest: undefined});
        }}>
            <Container style={{display: "flex", justifyContent: "center"}}>
                <Autocomplete
                    style={{margin: 8, minWidth: "120px"}}
                    value={newPortRuleRequest?.targetNode}
                    getOptionLabel={(s) => {
                        return s.uid;
                    }}
                    disabled={newPortRuleRequest?.targetNode != undefined}
                    renderInput={(params) => <TextField required {...params} label={"Target Node"}/>}
                    options={Object.values(this.props.dmsNodes).filter(n => {
                        // we want to exclude nodes that already have port forwarding configured
                        const hasPortForwardingRule = this.props.portRules.find(f => f.targetNode === n.uid) !== undefined;

                        return !hasPortForwardingRule;
                    })}
                    onChange={(event, newValue) => {
                        if (newValue) {
                            this.setState(prevState => ({
                                newPortRuleRequest: {
                                    ...prevState.newPortRuleRequest,
                                    targetNode: newValue
                                }
                            }), () => {
                                this.validateNewPortRuleRequest(this.state.newPortRuleRequest);
                            });
                        }
                    }}
                />
                <TextField
                    value={newPortRuleRequest?.hostname ?? "localhost"}
                    label={"Hostname"}
                    onChange={(event) => {
                        if (event.target.value) {
                            this.setState(prevState => ({
                                newPortRuleRequest: {
                                    ...prevState.newPortRuleRequest,
                                    hostname: event.target.value as string
                                }
                            }), () => {
                                this.validateNewPortRuleRequest(this.state.newPortRuleRequest, true);
                            });
                        }
                    }}
                    style={{margin: 8, minWidth: "120px"}}>

                </TextField>
                <FormControl style={{margin: 8, minWidth: "120px"}}>
                    <InputLabel>Node Port</InputLabel>
                    <Select
                        value={newPortRuleRequest?.nodePort}
                        onChange={(event) => {
                            if (event.target.value) {
                                const targetProtocol = this.state.supportedPorts.find(p => p.portNumber === event.target.value)?.protocol ?? "tcp";

                                this.setState(prevState => ({
                                    newPortRuleRequest: {
                                        ...prevState.newPortRuleRequest,
                                        nodePort: event.target.value as number,
                                        protocol: targetProtocol
                                    }
                                }), () => {
                                    this.validateNewPortRuleRequest(this.state.newPortRuleRequest, true);
                                });
                            }
                        }}
                    >
                        {
                            this.state.supportedPorts.map((n, i) => {
                                return <MenuItem key={i}
                                                 value={n.portNumber}>{`${n.serviceName} (${n.portNumber})`}</MenuItem>;
                            })
                        }
                    </Select>
                </FormControl>

                <TextField disabled={false}
                           value={newPortRuleRequest?.publicPort ?? "Not Set"}
                           onChange={(event) => {
                               if (!isNaN(Number(event.target.value))) {
                                   this.setState(prevState => ({
                                       newPortRuleRequest: {
                                           ...prevState.newPortRuleRequest,
                                           publicPort: Number(event.target.value)
                                       }
                                   }), () => {
                                       this.validateNewPortRuleRequest(this.state.newPortRuleRequest);
                                   });
                               }
                           }}
                           label={"Public Port"}
                           style={{margin: 8, minWidth: "120px"}}>

                </TextField>
            </Container>
        </ModalDialog>);
    };

    private renderDeletePortRuleDialog = () => {
        const {isLoadingPortRules} = this.props;

        return (<ModalDialog open={this.state.deletionTargetPortRule !== undefined}
                             buttonOkDisabled={this.state.isDeletingRuleInProgress}
                             buttonCancelDisabled={this.state.isDeletingRuleInProgress}
                             buttonOkIsLoading={this.state.isDeletingRuleInProgress}
                             title={`Are you sure you want to delete port forwarding rule for node '${this.state?.deletionTargetPortRule?.targetNode}'`}
                             onOk={() => {
                                 this.onDeletePortRuleRequested(this.state.deletionTargetPortRule);
                             }} onCancel={() => {
            this.setState({deletionTargetPortRule: undefined});
        }}>
            {"This action is permanent and cannot be undone!"}
            <LinearProgress
                style={{transition: "all ease-in-out 0ms", opacity: isLoadingPortRules ? 1 : 0, marginTop: 8}}
            />
        </ModalDialog>);
    };

    private onCreatePortRuleRequested = async (newPortRuleRequest: INewPortRuleRequest) => {
        if (!newPortRuleRequest?.validates) {
            return;
        }

        const jwtToken = LocalStorageHelper.getAuthToken();

        if (jwtToken === null) {
            console.log("missing jwt token");
            return;
        }

        this.setState({isCreatingRuleInProgress: true});

        try {
            const msg = DMSMessageFactory.newMessage<{
                rule: IPortForwardingRule
            }>(DMSMethod.NEW_PORT_FORWARDING_RULE, {
                rule: {
                    targetNode: newPortRuleRequest.targetNode?.uid,
                    nodePort: newPortRuleRequest.nodePort!,
                    publicPort: newPortRuleRequest.publicPort!,
                    hostname: newPortRuleRequest.hostname,
                    protocol: (newPortRuleRequest.protocol ? newPortRuleRequest.protocol : "tcp")
                }
            });

            await this.props.dmsClient.sendMessage(msg, true);
            await this.props.onLoadDataRequested();

            this.props.displaySnackbar(`Created port forwarding rule for node ${newPortRuleRequest!.targetNode}`, "info");
        } catch (error: any) {
            let errorText: string;

            if (error.response) {
                errorText = `${error.response.status}:${error.response.statusText}`;
            } else {
                errorText = JSON.stringify(error);
            }

            this.props.displaySnackbar(`Could not create port forwarding rule for node: ${newPortRuleRequest!.targetNode}: ${errorText}`, "error");
        } finally {
            this.setState({isCreatingRuleInProgress: false, newPortRuleRequest: undefined});
        }
    };

    private onDeletePortRuleRequested = async (portRule: IPortForwardingRule | undefined) => {
        const jwtToken = LocalStorageHelper.getAuthToken();

        if (jwtToken === null) {
            console.log("missing jwt token");
            return;
        }

        this.setState({isDeletingRuleInProgress: true});

        try {
            const msg = DMSMessageFactory.newMessage(DMSMethod.DELETE_PORT_FORWARDING_RULE, {
                uid: portRule!.targetNode,
            });

            await this.props.dmsClient.sendMessage(msg, true);

            await this.props.onLoadDataRequested();
            this.props.displaySnackbar(`Deleted port forwarding rule for node ${portRule!.targetNode}`, "info");
        } catch (error: any) {
            let errorText: string;

            if (error.response) {
                errorText = `${error.response.status}:${error.response.statusText}`;
            } else {
                errorText = JSON.stringify(error);
            }

            this.props.displaySnackbar(`Could not delete port forwarding rule for node: ${portRule!.targetNode}: ${errorText}`, "error");
        } finally {
            this.setState({
                isDeletingRuleInProgress: false,
                deletionTargetPortRule: undefined
            });
        }
    };
}
